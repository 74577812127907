import React from 'react';
import styled, { css } from 'styled-components';

import { Icon, IconName, IconSize } from '../Icon';

type ButtonSize = 's' | 'm' | 'l';

type ButtonType = 'normal' | 'ghost';

interface IconButtonProps {
  icon: IconName;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  iconSize?: IconSize;
  size?: ButtonSize;
  type?: ButtonType;
}

interface ContainerProps {
  size?: ButtonSize;
  kind?: ButtonType;
  disabled?: boolean;
}

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const { icon, onClick, className, disabled, iconSize, size = 'm', type = 'normal' } = props;

  return (
    <Container className={className} disabled={disabled} onClick={onClick} ref={ref} size={size} kind={type}>
      <Icon name={icon} size={iconSize} />
    </Container>
  );
});

IconButton.displayName = 'IconButton';

const Container = styled.button<ContainerProps>(({ theme, size, kind, disabled }) => [
  css`
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    transition: all 0.3s;
    & > * {
      margin 0 auto;
    }
  `,
  kind === 'normal' &&
    !disabled &&
    css`
      background-color: ${theme.palette.charcoal.solid[150]};
      color: ${theme.palette.charcoal.solid[600]};
      &:hover {
        background-color: ${theme.palette.charcoal.solid[200]};
        color: ${theme.palette.charcoal.solid[700]};
      }
    `,
  kind === 'ghost' &&
    !disabled &&
    css`
      background-color: transparent;
      color: ${theme.palette.charcoal.solid[500]};
      &:hover {
        background-color: ${theme.palette.charcoal.transparent[5]};
        color: ${theme.palette.charcoal.solid[600]};
      }
    `,
  disabled &&
    css`
      cursor: default;
    `,
  kind === 'ghost' &&
    disabled &&
    css`
      color: ${theme.palette.charcoal.solid[200]};
    `,
  kind === 'normal' &&
    disabled &&
    css`
      background-color: ${theme.palette.charcoal.solid[75]};
      color: ${theme.palette.charcoal.solid[300]};
    `,
  size === 's' &&
    css`
      height: 1.5rem;
      width: 1.5rem;
    `,
  size === 'm' &&
    css`
      height: 2rem;
      width: 2rem;
    `,
  size === 'l' &&
    css`
      height: 2.5rem;
      width: 2.5rem;
    `,
]);

export const theme: Theme.BaseTheme = {
  spacing: {
    min: '0.0625rem',
    xxxs: '0.125rem',
    xxs: '0.25rem',
    xs: '0.5rem',
    s: '0.75rem',
    m: '1rem',
    l: '1.25rem',
    xl: '1.5rem',
    xxl: '2rem',
  },
  palette: {
    charcoal: {
      solid: {
        0: '#FFFFFF',
        25: '#F7F8FA',
        50: '#EFF1F4',
        75: '#E8EAEE',
        100: '#E1E3E8',
        150: '#D4D7DD',
        200: '#C4C7D0',
        250: '#B5B9C4',
        300: '#A7ABB8',
        400: '#8A909F',
        500: '#6E7585',
        600: '#535A6B',
        700: '#383F51',
        800: '#232939',
        900: '#10141E',
        1000: '#000000',
      },
      transparent: {
        5: 'rgba(110, 117, 133, 0.05)',
        10: 'rgba(110, 117, 133, 0.10)',
        20: 'rgba(110, 117, 133, 0.20)',
      },
    },
    trueBlue: {
      solid: {
        500: '#457AC4',
      },
    },
    tartOrange: {
      solid: {
        25: '#FFEBEB',
        500: '#E5291F',
      },
    },
  },
  typography: {
    fontSize: {
      xxs: '0.6875rem',
      xs: '0.75rem',
      s: '0.875rem',
      m: '1rem',
      l: '1.125rem',
      xl: '1.25rem',
      xxl: '1.5rem',
      xxxl: '2rem',
    },
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
  },
};

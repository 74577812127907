import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { GuardedRoute } from '../auth/GuardedRoute';
import { PageClients } from './pages/Clients';
import { Login } from './pages/Login';

export const App: React.FC = () => {
  return (
    <AppContainer>
      <Routes>
        <Route
          path="/"
          element={
            <GuardedRoute>
              <PageClients />
            </GuardedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AppContainer>
  );
};

const AppContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.palette.charcoal.solid[25]};
    height: 100vh;
    width: 100vw;
  `,
);

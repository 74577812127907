import React from 'react';

export function Sort() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
      <path d="M3 7H21" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M6 12H18" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M10 17H14" strokeWidth="1.5" strokeLinecap="round" />{' '}
    </svg>
  );
}

import React from 'react';
import styled, { css } from 'styled-components';

import { useClientState, useClientActions } from './Context';
import { Button, Input } from '@@components';

export interface IndustryList {
  guid: string;
  name: string;
}

interface ToolBarProps {
  onAddClient: () => void;
  industriesList?: IndustryList[];
}

export const ToolBar: React.FC<ToolBarProps> = (props) => {
  const { onAddClient } = props;
  const { searchedValue } = useClientState();
  const { searchClient, filterSearchData } = useClientActions();

  return (
    <Container>
      <Field>
        <Label>Szukaj grupy</Label>
        <Input
          id="toolbar-search"
          value={searchedValue}
          onChange={searchClient}
          onBlur={searchClient}
          placeholder="Wpisz nazwę"
          actionIcon="Search"
          actionHandler={filterSearchData}
          disabledAction={!searchedValue}
          width={300}
        />
      </Field>
      <Button onClick={onAddClient} label="Dodaj" disabled={!searchedValue} />
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    padding: ${theme.spacing.m} ${theme.spacing.l};
    justify-content: space-between;
    width: 100%;
  `,
);

const Field = styled.div(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    & > *:not(:first-child) {
      margin-left: ${theme.spacing.m};
    }
  `,
);

const Label = styled.label``;

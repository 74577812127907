import React from 'react';

export function Close() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
      <path d="M7 7L17 17" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M7 17L17 7" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}

import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { gql, useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { Button, Icon, Input } from '@@components';
import { useAuth } from '../../../auth';

export const Login: React.FC = () => {
  const [userName, setUserName] = useState<string>('');
  const [password, setPasswrod] = useState<string>('');
  const { login } = useAuth();
  const [logIn, { data, loading, error }] = useLazyQuery<{ token: string }>(LOG_IN);
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.token) {
      login(data.token);
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Container>
      <Content>
        <Input id="name" label="Login" placeholder="Podaj email lub login" value={userName} onChange={setUserName} onBlur={setUserName} />
        <Input
          id="password"
          label="Hasło"
          placeholder="Wpisz hasło"
          value={password}
          onChange={setPasswrod}
          onBlur={setPasswrod}
          validationError={error?.message}
          type="password"
        />
        <Button
          label="Log In"
          onClick={() =>
            logIn({
              variables: { credentials: { login: userName, password } },
            })
          }
          disabled={!userName || !password}
        />
      </Content>
      {loading && (
        <Loader>
          <LoadingIcon name="NotificationCircle" size={4} />
        </Loader>
      )}
    </Container>
  );
};

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Content = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 20rem;
    & > *:not(:first-child) {
      margin-top: ${theme.spacing.l};
    }
  `,
);

const LoadingIcon = styled(Icon)``;

const Loader = styled.div(
  ({ theme }) => css`
    align-items: center;
    background-color: ${theme.palette.charcoal.transparent[5]};
    backdrop-filter: blur(2px);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;

    & ${LoadingIcon} {
      animation: ${rotate} 1s linear infinite;
    }
  `,
);

const LOG_IN = gql`
  query LogIn($credentials: Login!) {
    token: logIn(credentials: $credentials)
  }
`;

import { useState, useEffect } from 'react';

interface UsePortal {
  (): PortalReturn;
}

interface PortalReturn {
  portalRoot: HTMLElement | undefined;
  zIndex: number;
}

export const usePortal: UsePortal = () => {
  const [attributes, setAttributes] = useState<PortalReturn>({ portalRoot: undefined, zIndex: 99 });

  useEffect(() => {
    const portalRoot = document.getElementById('react-portal-root') || document.body;
    const zIndex = 99 + portalRoot.children.length;
    setAttributes({ portalRoot, zIndex });
  }, []);

  // console.log('portal', attributes.portalRoot, attributes.portalRoot?.children.length, attributes.zIndex);

  return attributes;
};

import React from 'react';
import styled, { css } from 'styled-components';
import {
  ArrowDown,
  Briefcase,
  Close,
  Edit,
  Filter,
  GlobalSearch,
  Logout,
  Menu,
  NotificationCircle,
  ProgsArrows,
  Search,
  Sort,
  Trash,
  UserSearch,
} from './icons';

export type IconName =
  | 'ArrowDown'
  | 'Briefcase'
  | 'Close'
  | 'Edit'
  | 'Filter'
  | 'GlobalSearch'
  | 'Logout'
  | 'Menu'
  | 'NotificationCircle'
  | 'ProgsArrows'
  | 'Search'
  | 'Sort'
  | 'Trash'
  | 'UserSearch';

export type IconSize = 'xxs' | 'xs' | 's' | 'm' | number;

interface IconProps {
  name: IconName;
  className?: string;
  size?: IconSize;
}

const iconsResolver: Record<IconName, React.FunctionComponent> = {
  ArrowDown: ArrowDown,
  Briefcase: Briefcase,
  Close: Close,
  Edit: Edit,
  Filter: Filter,
  GlobalSearch: GlobalSearch,
  Logout: Logout,
  Menu: Menu,
  NotificationCircle: NotificationCircle,
  ProgsArrows: ProgsArrows,
  Search: Search,
  Sort: Sort,
  Trash: Trash,
  UserSearch: UserSearch,
};

export const Icon = React.forwardRef<HTMLElement, IconProps>((props, ref) => {
  const { name, className, size = 'm' } = props;

  const IconToRender = iconsResolver[name];

  if (!IconToRender) return null;

  return (
    <Container className={className} size={size} ref={ref}>
      <IconToRender />
    </Container>
  );
});

Icon.displayName = 'Icon';

const Container = styled.figure<Pick<IconProps, 'size'>>(({ size }) => [
  typeof size === 'number' &&
    css`
      height: ${size}rem;
      width: ${size}rem;
    `,
  size === 'm' &&
    css`
      height: 1.5rem;
      width: 1.5rem;
    `,
  size === 's' &&
    css`
      height: 1.25rem;
      min-height: 1.25rem;
      min-width: 1.25rem;
      width: 1.25rem;
    `,
  size === 'xs' &&
    css`
      height: 1rem;

      width: 1rem;
    `,
  size === 'xxs' &&
    css`
      height: 0.75rem;
      width: 0.75rem;
    `,
]);

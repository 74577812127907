import React, { PropsWithChildren, useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import { useClickOutside, usePortal } from '@@hooks';

interface DropdownProps {
  isVisible: boolean;
  onClose: () => void;
  refElement: React.RefObject<HTMLElement>;
}

interface DropdownPosition {
  top: number;
  left: number;
  width?: number;
}

export const Dropdown: React.FC<PropsWithChildren<DropdownProps>> = (props) => {
  const { children, isVisible, onClose, refElement } = props;
  const [rects, setRects] = useState<DropdownPosition>({ top: 0, left: 0, width: 0 });
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { portalRoot, zIndex } = usePortal();
  useClickOutside(dropdownRef, onClose, [refElement]);

  const { top = 0, height = 0, left = 0, width = 0 } = refElement.current?.getBoundingClientRect() ?? {};

  useEffect(() => {
    const offsetTop = top + height;
    setRects((prevState) =>
      prevState.left !== left || prevState.top !== offsetTop ? { ...prevState, top: offsetTop, left, width } : prevState,
    );
  }, [top, height, left, width]);

  return createPortal(
    isVisible ? (
      <Container rects={rects} zIndex={zIndex} ref={dropdownRef}>
        {children}
      </Container>
    ) : null,
    portalRoot || document.body,
  );
};

const Container = styled.div<{ rects: DropdownPosition; zIndex: number }>(
  ({ theme, rects, zIndex }) => css`
    background-color: ${theme.palette.charcoal.solid[50]};
    box-shadow: 0px 0px 16px 0px ${theme.palette.charcoal.transparent[20]};
    left: ${rects.left}px;
    position: absolute;
    top: ${rects.top}px;
    min-width: ${rects.width}px;
    z-index: ${zIndex};
  `,
);

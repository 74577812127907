import { useEffect, RefObject } from 'react';

export const useClickOutside = (referenceRef: RefObject<Element> | null, callback: () => void, exculdeRefs?: RefObject<Element>[]) => {
  const clickHandler = (e: Event) => {
    const isRefElement = !!referenceRef?.current;
    const isTargetOutsideRefElement = isRefElement && !referenceRef?.current?.contains(e.target as Node);
    const isNotAtExculdeList = !exculdeRefs?.some(
      (excludeRef) => excludeRef.current === e.target || excludeRef.current?.contains(e.target as Node),
    );

    if (isRefElement && isTargetOutsideRefElement && isNotAtExculdeList) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', clickHandler);

    return () => {
      document.removeEventListener('click', clickHandler);
    };
  });
};

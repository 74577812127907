import React from 'react';
import styled, { css } from 'styled-components';
import { IconButton } from '../Button';

import { Icon, IconName } from '../Icon';
import { UserData } from '../../auth';

export interface TableColumn<T> {
  key: keyof T;
  label: string;
  icon?: IconName;
  width?: number;
  type?: 'text' | 'action';
}

interface DataBaseProps<T> {
  columns: TableColumn<T>[];
  data?: T[];
  width?: number;
  allowActions?: boolean;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  userData?: UserData;
}

export function DataTable<T extends { guid: string; enabledActions?: boolean }>(props: DataBaseProps<T>): JSX.Element {
  const { columns, data, width, allowActions, onEdit, onDelete } = props;
  const isTableFixed = columns.some(({ width }) => !!width);

  return (
    <Container width={!isTableFixed ? width : undefined}>
      <div>
        <Table isTableFixed={isTableFixed}>
          <colgroup>
            {isTableFixed ? (
              <>
                {columns.map(({ key, width }) => (
                  <col key={key as string} style={{ width: `${width}px` }} />
                ))}
                {allowActions && <col style={{ width: 120 }} />}
              </>
            ) : null}
          </colgroup>
          <TableHead>
            <TableRow>
              {columns.map(({ key, label, icon }) => (
                <TableHeadData key={key as string}>
                  <Header>
                    {icon && <Icon name={icon} size="s" />}
                    <Label>{label}</Label>
                  </Header>
                </TableHeadData>
              ))}
              {allowActions && (
                <TableHeadData>
                  <Header>
                    {<Icon name="ProgsArrows" size="s" />}
                    <Label>Akcje</Label>
                  </Header>
                </TableHeadData>
              )}
            </TableRow>
          </TableHead>
        </Table>
      </div>
      <TableBodyWrapper>
        <Table isTableFixed={isTableFixed}>
          <colgroup>
            {isTableFixed ? (
              <>
                {columns.map(({ key, width }) => (
                  <col key={key as string} style={{ width: `${width}px` }} />
                ))}
                {allowActions && <col style={{ width: 120 }} />}
              </>
            ) : null}
          </colgroup>
          <TableBody>
            {data?.map((item) => (
              <TableRow key={item.guid}>
                {columns.map(({ key }) => (
                  <TableData key={key as string}>{item[key] as string}</TableData>
                ))}
                {allowActions && (
                  <TableData>
                    <ActionWrapper>
                      <IconButton
                        icon="Edit"
                        onClick={() => onEdit?.(item.guid)}
                        size="m"
                        iconSize="xs"
                        type="ghost"
                        disabled={!item.enabledActions}
                      />
                      <IconButton
                        icon="Trash"
                        onClick={() => onDelete?.(item.guid)}
                        size="m"
                        iconSize="xs"
                        type="ghost"
                        disabled={!item.enabledActions}
                      />
                    </ActionWrapper>
                  </TableData>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableBodyWrapper>
    </Container>
  );
}

const Container = styled.div<{ width?: number }>(({ width }) => [
  css`
    display: flex;
    max-height: calc(100vh - 5rem);
    flex-direction: column;
    justify-content: center;
    width: 100%;
  `,
  width &&
    css`
      min-width: ${width}px;
      width: ${width}px;
    `,
]);

const Table = styled.table<{ isTableFixed: boolean }>(({ theme, isTableFixed }) => [
  css`
    background-color: ${theme.palette.charcoal.solid[50]};
    width: 100%;
  `,
  isTableFixed &&
    css`
      table-layout: fixed;
      width: unset;
    `,
]);

const TableHead = styled.thead(
  ({ theme }) => css`
    background-color: ${theme.palette.charcoal.solid[100]};
  `,
);

const TableBody = styled.tbody(
  ({ theme }) => css`
    & ${TableRow} {
      &:hover {
        background-color: ${theme.palette.charcoal.transparent[5]};
      }
    }
  `,
);

const TableData = styled.td(
  ({ theme }) => css`
    padding: 0 ${theme.spacing.l};
    overflow: hidden;
    word-break: break-all;
  `,
);

const TableHeadData = styled(TableData)(
  ({ theme }) => css`
    font-weight: ${theme.typography.fontWeight.bold};
  `,
);

const TableRow = styled.tr(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.charcoal.solid[150]};
    height: 2.5rem;
  `,
);

const Header = styled.div(
  ({ theme }) => css`
    align-items: center;
    color: ${theme.palette.charcoal.solid[500]};
    display: flex;
  `,
);

const Label = styled.span(
  ({ theme }) => css`
    color: ${theme.palette.charcoal.solid[700]};
    margin-left: ${theme.spacing.s};
  `,
);

const TableBodyWrapper = styled.div`
  overflow: auto;
  & ${TableRow}:last-child {
    border-bottom: none;
  }
`;

const ActionWrapper = styled.div(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    & > *:not(:first-child) {
      margin-left: ${theme.spacing.xxs};
    }
  `,
);

import React, { useState, PropsWithChildren, useContext, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface AuthInterface {
  token: string | null;
  userData: UserData | null;
  login: (token: string) => void;
  logout: () => void;
}

export interface UserData {
  id: string;
  login: string;
  name: string;
  role: string;
}

const parseToken = (token: string) => {
  const chunks = token.split('.');
  return JSON.parse(atob(chunks[1]));
};

const AuthContext = React.createContext<AuthInterface | undefined>(undefined);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [token, setToken] = useState<string | null>(() => sessionStorage.getItem('token'));
  const [userData, setUserData] = useState<UserData | null>(null);
  const navigate = useNavigate();

  const login: AuthInterface['login'] = useCallback((recievedToken) => {
    setToken(recievedToken);
  }, []);

  const logout = useCallback(() => {
    setToken(null);
  }, []);

  useEffect(() => {
    if (token) {
      const { id, login, name, role } = parseToken(token);
      sessionStorage.setItem('token', token);
      setUserData({ id, login, name, role });
      navigate('/');
    }
    if (!token) {
      sessionStorage.removeItem('token');
      setUserData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return <AuthContext.Provider value={{ token, userData, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext<AuthInterface | undefined>(AuthContext);
  if (!context) throw new Error('`useAuth` must be used within `AuthContext`');
  return context;
};

import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './auth';
import { ThemeProvider } from './theme/ThemeProvider';
import { App } from './app';

const URL = process.env.API_URL;
const client = new ApolloClient({
  uri: URL,
  cache: new InMemoryCache(),
});

const entrypoint = document.getElementById('react-root');
const root = createRoot(entrypoint || document.body);

root.render(
  <ThemeProvider>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </ApolloProvider>
  </ThemeProvider>,
);

import React, { useReducer, useContext, PropsWithChildren } from 'react';
import { Client } from './types';

interface ClientState {
  clients: Client[];
  searchedValue: string;
  searchedClients: Client[] | null;
}

type ClientAction = { type: 'SET_DATA'; data: Client[] } | { type: 'SEARCH_CLIENT'; value: string } | { type: 'FILTER_SEARCH_DATA' };

interface ClientActions {
  setData: (data: Client[]) => void;
  searchClient: (value: string) => void;
  filterSearchData: () => void;
}

interface Reducer {
  (state: ClientState, action: ClientAction): ClientState;
}

const initialState = { clients: [], searchedValue: '', searchedClients: null };

const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        clients: action.data,
      };
    case 'SEARCH_CLIENT':
      return {
        ...state,
        searchedValue: action.value,
        searchedClients: state.searchedValue > action.value ? initialState.searchedClients : state.searchedClients,
      };
    case 'FILTER_SEARCH_DATA':
      return {
        ...state,
        searchedClients: state.clients.filter((client) => client.socialMediaGroup?.includes(state.searchedValue)),
      };
    default:
      return state;
  }
};

const ClientStateContext = React.createContext<ClientState | undefined>(undefined);
const ClientActionsContext = React.createContext<ClientActions | undefined>(undefined);

export const ClientContext: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState);

  const setData: ClientActions['setData'] = (data) => {
    dispatch({ type: 'SET_DATA', data });
  };

  const searchClient: ClientActions['searchClient'] = (value) => {
    dispatch({ type: 'SEARCH_CLIENT', value });
  };

  const filterSearchData: ClientActions['filterSearchData'] = () => {
    dispatch({ type: 'FILTER_SEARCH_DATA' });
  };

  return (
    <ClientStateContext.Provider value={state}>
      <ClientActionsContext.Provider value={{ setData, searchClient, filterSearchData }}>{children}</ClientActionsContext.Provider>
    </ClientStateContext.Provider>
  );
};

export const useClientState = (): ClientState => {
  const context = useContext(ClientStateContext);
  if (context === undefined) {
    throw new Error('useFormState must be used within ClientContext');
  }
  return context;
};

export const useClientActions = (): ClientActions => {
  const context = useContext(ClientActionsContext);
  if (context === undefined) {
    throw new Error('useFormAction must be used within ClientContext');
  }
  return context;
};

import React from 'react';
import styled, { css } from 'styled-components';

type ButtonSize = 'm' | 'l';
type ButtonType = 'normal' | 'ghost';

interface ButtonProps {
  label: string;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  size?: ButtonSize;
  type?: ButtonType;
}

interface ContainerProps {
  size?: ButtonSize;
  kind?: ButtonType;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const { label, onClick, className, disabled = false, size = 'l', type = 'normal' } = props;

  return (
    <Container className={className} onClick={onClick} size={size} disabled={disabled} kind={type}>
      {label}
    </Container>
  );
};

const Container = styled.button<ContainerProps>(({ theme, size, kind, disabled }) => [
  css`
    cursor: pointer;
    font-weight: ${theme.typography.fontWeight.medium};
    padding: 0 ${theme.spacing.xxl};
    transition: all 0.3s;
  `,
  kind === 'normal' &&
    !disabled &&
    css`
      background-color: ${theme.palette.charcoal.solid[150]};
      color: ${theme.palette.charcoal.solid[700]};
      &:hover {
        background-color: ${theme.palette.charcoal.solid[200]};
        color: ${theme.palette.charcoal.solid[800]};
      }
    `,
  kind === 'ghost' &&
    !disabled &&
    css`
      background-color: transparent;
      color: ${theme.palette.charcoal.solid[600]};
      &:hover {
        background-color: ${theme.palette.charcoal.transparent[5]};
        color: ${theme.palette.charcoal.solid[700]};
      }
    `,
  disabled &&
    css`
      cursor: not-allowed;
    `,
  kind === 'ghost' &&
    disabled &&
    css`
      color: ${theme.palette.charcoal.solid[200]};
    `,
  kind === 'normal' &&
    disabled &&
    css`
      background-color: ${theme.palette.charcoal.solid[75]};
      color: ${theme.palette.charcoal.solid[300]};
    `,
  size === 'm' &&
    css`
      height: 2rem;
    `,
  size === 'l' &&
    css`
      height: 2.5rem;
    `,
  disabled &&
    css`
      background-color: ${theme.palette.charcoal.solid[75]};
      color: ${theme.palette.charcoal.solid[300]};
    `,
]);

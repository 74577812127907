import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useMutation } from '@apollo/client';

import { Button, Input, Modal, Select, SelectOption } from '@@components';
import { useClientState } from './Context';
import { ADD_CLIENT, GET_CLIENTS, ADD_INDUSTRY, EDIT_CLIENT, GET_INDUSTRIES } from './queries';

import type { Client } from './types';
import { useAuth } from '../../../auth';

interface AddClientModalProps {
  isVisible: boolean;
  onClose: () => void;
  industriesOptions: SelectOption[];
  onSuccess: () => void;
  editableData?: Client | null;
}

export const AddClientModal: React.FC<AddClientModalProps> = (props) => {
  const { isVisible, onClose, industriesOptions, onSuccess, editableData } = props;
  const { searchedValue } = useClientState();
  const { userData } = useAuth();
  const [socialMediaGroup, setSocialMediaGroup] = useState<string>('');
  const [industryId, setIndustryId] = useState<string | null>('');

  const [addClient, { error: addClientError }] = useMutation(ADD_CLIENT, { refetchQueries: [{ query: GET_CLIENTS }] });
  const [editClient, { error: editClientError }] = useMutation(EDIT_CLIENT, { refetchQueries: [{ query: GET_CLIENTS }] });
  const [addIndustry, { error: addIndustryError }] = useMutation(ADD_INDUSTRY, { refetchQueries: [{ query: GET_INDUSTRIES }] });

  useEffect(() => {
    setSocialMediaGroup(editableData?.socialMediaGroup ?? '');
    setIndustryId(editableData?.industryId ?? '');
  }, [editableData]);

  useEffect(() => {
    if (!isVisible && searchedValue) {
      setIndustryId(null);
      setSocialMediaGroup(searchedValue);
    }
  }, [isVisible, searchedValue]);

  const handleSubmit = async () => {
    const result = await addClient({
      variables: { input: { userId: userData?.id || null, socialMediaGroup: socialMediaGroup || null, industryId } },
    });
    result && onSuccess();
  };

  const handleSubmitEdit = async () => {
    const result = await editClient({
      variables: { input: { guid: editableData?.guid || null, socialMediaGroup: socialMediaGroup || null, industryId } },
    });
    result && onSuccess();
  };

  const handleAddIndustry = async (value: string) => {
    const result = await addIndustry({ variables: { name: value } });
    if (result) {
      setIndustryId(result.data.addIndustry);
    }
    return !!result;
  };

  return (
    <Modal isVisible={isVisible} onClose={onClose} title="Nowy klient">
      <ModalContent>
        <Inputs>
          <Input
            id="setSocialMediaGroup"
            value={socialMediaGroup}
            onChange={setSocialMediaGroup}
            onBlur={setSocialMediaGroup}
            placeholder="Podaj nazwę grupy"
            width={320}
            label="Grupa Social Media"
          />
          <Select
            id="industry"
            value={industryId ?? ''}
            onSelect={(value) => setIndustryId(value || null)}
            options={industriesOptions}
            allowAddOption
            addOptionValidationError={addIndustryError?.message}
            onAddOption={handleAddIndustry}
            placeholder="Wybierz branżę"
            width={240}
            label="Branża"
          />
        </Inputs>
        <Error>{addClientError?.message}</Error>
        <Error>{editClientError?.message}</Error>
        {editableData ? (
          <Button onClick={handleSubmitEdit} label="Edit" disabled={!socialMediaGroup} />
        ) : (
          <Button onClick={handleSubmit} label="Dodaj" disabled={!userData?.id || !socialMediaGroup} />
        )}
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div(
  ({ theme }) => css`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    & > *:not(:first-child) {
      margin-top: ${theme.spacing.m};
    }
  `,
);

const Inputs = styled.div(
  ({ theme }) => css`
    display: flex;
    & > *:not(:first-child) {
      margin-left: ${theme.spacing.m};
    }
  `,
);

const Error = styled.span(({ theme, children }) => [
  css`
    color: ${theme.palette.tartOrange.solid[500]};
    font-size: ${theme.typography.fontSize.s};
    width: 100%;
  `,
  !children &&
    css`
      dispaly: none;
    `,
]);

import React, { PropsWithChildren } from 'react';
import { createGlobalStyle, ThemeProvider as STP, css } from 'styled-components';

import { theme } from './theme';

const resetRangeInput = css`
  input[type='range'] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
`;

const GlobalStyle = createGlobalStyle(
  () => css`
    @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap');
    *,
    *:before,
    *:after {
      box-sizing: border-box;
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      margin: 0;
      outline: none;
      padding: 0;
      text-decoration: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    a:hover,
    a:visited,
    a:active,
    a:focus {
      // color: inherit;
      text-decoration: none;
    }
    button,
    input,
    option,
    textarea,
    select {
      background-color: transparent;
      border: none;
    }
    ol,
    ul,
    li {
      list-style: none;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }
    html {
      font-family: Nunito, sans-serif;
      font-size: 16px;
    }
    ${resetRangeInput}

    body {
      position: relative;
      color: ${theme.palette.charcoal.solid[700]};
    }
  `,
);

export const ThemeProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => (
  <STP theme={theme}>
    <GlobalStyle />
    {children}
  </STP>
);

import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Modal } from '@@components';

interface DeleteModalProps {
  id: string | null;
  onClose: () => void;
  onConfirm: () => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = (props) => {
  const { id, onClose, onConfirm } = props;

  return (
    <Modal isVisible={!!id} onClose={onClose} title="Usunąć?">
      <CancelButton label="Anuluj" onClick={onClose} />
      <Button label="Unuń" onClick={onConfirm} type="ghost" />
    </Modal>
  );
};

const CancelButton = styled(Button)(
  ({ theme }) => css`
    margin-right: ${theme.spacing.m};
  `,
);

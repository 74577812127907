import React from 'react';

export function ProgsArrows() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
      <path d="M19 16V6.5C19 5.4 18.1 4.5 17 4.5H11.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 2L11 4.5L14 7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M19 22C20.6569 22 22 20.6569 22 19C22 17.3431 20.6569 16 19 16C17.3431 16 16 17.3431 16 19C16 20.6569 17.3431 22 19 22Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5 8V17.5C5 18.6 5.9 19.5 7 19.5H12.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 22L13 19.5L10 17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5 8C6.65685 8 8 6.65685 8 5C8 3.34315 6.65685 2 5 2C3.34315 2 2 3.34315 2 5C2 6.65685 3.34315 8 5 8Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { gql } from '@apollo/client';

export const ADD_CLIENT = gql`
  mutation AddClient($input: NewClient) {
    addClient(input: $input)
  }
`;

export const GET_CLIENTS = gql`
  query GetClients {
    clients: getAllClients {
      guid
      userName
      userId
      socialMediaGroup
      industryId
      industry
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query GetIndusties {
    industriesList {
      guid
      name
    }
  }
`;

export const ADD_INDUSTRY = gql`
  mutation AddIndustry($name: String!) {
    addIndustry(name: $name)
  }
`;

export const DELETE_CLIENT = gql`
  mutation DeleteClient($id: String!) {
    deleteClient(id: $id)
  }
`;

export const EDIT_CLIENT = gql`
  mutation EditClient($input: EditClient) {
    editClient(input: $input)
  }
`;

import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import { IconButton } from '../Button';
import { usePortal } from '@@hooks';

interface ModalProps {
  children: React.ReactNode;
  isVisible: boolean;
  onClose: () => void;
  cancelable?: boolean;
  closableMask?: boolean;
  title?: string;
  width?: number | '100%';
}

export const Modal: React.FC<ModalProps> = (props) => {
  const { children, isVisible, onClose, title, cancelable = true, closableMask = false, width } = props;
  const { portalRoot, zIndex } = usePortal();
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleClickMask = (e: React.MouseEvent<HTMLDivElement>) => {
    // Close modal when mask is closable
    if (cancelable && closableMask && e.currentTarget === e.target) {
      onClose();
    }
  };

  if (!isVisible) return null;

  return createPortal(
    <Container ref={containerRef} onClick={handleClickMask} zIndex={zIndex}>
      <Content ref={contentRef} width={width}>
        {cancelable && (
          <Header>
            <Title>{title}</Title>
            <CloseButton icon="Close" onClick={onClose} type="ghost" />
          </Header>
        )}
        <Main>{children}</Main>
      </Content>
    </Container>,
    portalRoot ?? document.body,
  );
};

const Container = styled.div<{ zIndex: number }>(
  ({ theme, zIndex }) => css`
    align-items: center;
    backdrop-filter: blur(2px);
    background-color: ${theme.palette.charcoal.transparent[20]};
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: ${zIndex};
  `,
);

const Content = styled.div<{ width: ModalProps['width'] }>(({ theme, width }) => [
  css`
    background-color: ${theme.palette.charcoal.solid[25]};
    box-shadow: 0 0 1.5rem 0 ${theme.palette.charcoal.solid[150]};
  `,
  width &&
    css`
      width: ${typeof width === 'number' ? `${width}px` : width};
    `,
]);

const Header = styled.header`
  align-items: center;
  display: flex;
  height: 2.5rem;
  position: relative;
`;

const Title = styled.h5(
  ({ theme }) => css`
    font-size: ${theme.typography.fontSize.l};
    font-weight: ${theme.typography.fontWeight.semiBold};
    padding: 0 ${theme.spacing.l};
  `,
);

const Main = styled.main(
  ({ theme }) => css`
    padding: ${theme.spacing.l};
  `,
);

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

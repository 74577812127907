import React from 'react';

import { ClientContext } from './Context';
import { Content } from './Content';
import { TopBar } from './TopBar';

export const PageClients: React.FC = () => {
  return (
    <ClientContext>
      <TopBar />
      <Content />
    </ClientContext>
  );
};

import React from 'react';
import styled, { css } from 'styled-components';
import { IconButton, IconName } from '../';

interface InputProps {
  id: string;
  onBlur: (value: string) => void;
  onChange: (value: string) => void;
  value: string;
  actionIcon?: IconName;
  actionHandler?: () => void;
  className?: string;
  disabledAction?: boolean;
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  type?: 'text' | 'password';
  validationError?: string;
  width?: number;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    id,
    onBlur,
    onChange,
    value,
    actionIcon,
    actionHandler,
    className,
    disabledAction,
    label,
    placeholder,
    readOnly,
    type = 'text',
    validationError,
    width,
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e.target.value);
  };

  const handleButtonClick = () => {
    actionHandler?.();
  };

  return (
    <Container width={width}>
      <InputWrapper error={!!validationError}>
        <Label>{label}</Label>
        <Base
          id={id}
          className={className}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={readOnly}
          ref={ref}
          type={type}
        />
        {actionIcon && <ActionButton icon={actionIcon} onClick={handleButtonClick} type="ghost" iconSize="s" disabled={disabledAction} />}
        <Border />
      </InputWrapper>
      <Error>{validationError}</Error>
    </Container>
  );
});

Input.displayName = 'Input';

const Container = styled.div<{ width?: number }>(({ theme, width }) => [
  css`
    width: 100%;
    &:hover {
      ${Border} {
        background-color: ${theme.palette.charcoal.solid[300]};
      }
    }
    &:focus-within {
      ${Border} {
        background-color: ${theme.palette.charcoal.solid[500]};
      }
    }
  `,
  width &&
    css`
      width: ${width}px;
    `,
]);

const Base = styled.input<{ readOnly?: boolean }>(({ theme, readOnly }) => [
  css`
    color: ${theme.palette.charcoal.solid[800]};
    height: 2.5rem;
    padding: 0 ${theme.spacing.m};
    width: 100%;
    &::placeholder {
      color: ${theme.palette.charcoal.solid[400]};
    }
  `,
  readOnly &&
    css`
      background-color: transparent;
    `,
]);

const Border = styled.div(
  ({ theme }) => css`
    background-color: ${theme.palette.charcoal.solid[150]};
    bottom: 0;
    height: 0.0625rem;
    position: absolute;
    transition: background-color 0.3s;
    width: 100%;
  `,
);

const InputWrapper = styled.div<{ error: boolean }>(({ theme, error }) => [
  css`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  `,
  error &&
    css`
      & ${Base} {
        background-color: ${theme.palette.tartOrange.solid[25]};
      }
      & ${Border} {
        background-color: ${theme.palette.tartOrange.solid[500]};
      }
    `,
]);

const ActionButton = styled(IconButton)(
  ({ theme }) => css`
    position: absolute;
    right: ${theme.spacing.xxs};
    top: ${theme.spacing.xxs};
  `,
);

const Label = styled.label(({ theme, children }) => [
  css`
    font-size: ${theme.spacing.s};
  `,
  !children &&
    css`
      display: none;
    `,
]);

const Error = styled.span(({ theme, children }) => [
  css`
    color: ${theme.palette.tartOrange.solid[500]};
    font-size: ${theme.typography.fontSize.s};
  `,
  !children &&
    css`
      dispaly: none;
    `,
]);

import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Dropdown, Icon, Modal, Input } from '../';

export interface SelectOption {
  label: string;
  value: string;
}

interface SelectProps {
  id: string;
  onSelect: (value: string) => void;
  options: SelectOption[];
  value: string;
  allowAddOption?: boolean;
  addOptionValidationError?: string;
  className?: string;
  label?: string;
  onAddOption?: (value: string) => Promise<boolean>;
  placeholder?: string;
  width?: number;
}

export const Select: React.FC<SelectProps> = (props) => {
  const { id, onSelect, options, value, allowAddOption, className, label, onAddOption, placeholder, addOptionValidationError, width } =
    props;
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const selectRef = useRef<HTMLInputElement>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [newPositionValue, setNewPositionValue] = useState('');

  const selectedValue = useMemo(() => options.find((option) => option.value === value)?.label ?? '', [value, options]);

  const handleSelectOption = (v: string) => () => {
    onSelect(v);
    setOpenDropdown(false);
  };

  const handleAddOption = async () => {
    const result = await onAddOption?.(newPositionValue);
    result && setIsModalOpen(false);
  };

  const handleClick = () => {
    setOpenDropdown((prevState) => !prevState);
  };

  useEffect(() => {
    // Clear input values when modal is closed
    if (!isModalOpen) {
      setNewPositionValue('');
    }
  }, [isModalOpen]);

  return (
    <Container ref={selectRef}>
      <InputWrapper>
        <Label>{label}</Label>
        <Base
          id={id}
          className={className}
          placeholder={placeholder}
          value={selectedValue}
          onClick={handleClick}
          width={width}
          readOnly={true}
        />
        <DropdownIcon name="ArrowDown" isOpen={openDropdown} />
        <Border />
      </InputWrapper>
      <Dropdown isVisible={openDropdown} onClose={() => setOpenDropdown(false)} refElement={selectRef}>
        {allowAddOption && onAddOption && (
          <Option onClick={() => setIsModalOpen(true)} type="button">
            + Dodaj
          </Option>
        )}
        {options.map(({ label, value }) => (
          <Option key={value} onClick={handleSelectOption(value)} width={width}>
            {label}
          </Option>
        ))}
      </Dropdown>

      <Modal isVisible={isModalOpen} onClose={() => setIsModalOpen(false)} title="Dodaj nową pozycję">
        <ModalContent>
          <Input
            id="new-position"
            value={newPositionValue}
            onChange={setNewPositionValue}
            onBlur={setNewPositionValue}
            placeholder="Wpisz nazwę pozycji"
            width={300}
            label="Nowa pozycja"
            validationError={addOptionValidationError}
          />
          <Button label="Dodaj" onClick={handleAddOption} disabled={!newPositionValue} />
        </ModalContent>
      </Modal>
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => css`
    &:hover {
      ${Border} {
        background-color: ${theme.palette.charcoal.solid[300]};
      }
    }
    &:focus-within {
      ${Border} {
        background-color: ${theme.palette.charcoal.solid[500]};
      }
    }
  `,
);

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const Base = styled.input<{ width?: number }>(({ theme, width }) => [
  css`
    color: ${theme.palette.charcoal.solid[800]};
    cursor: pointer;
    height: 2.5rem;
    min-width: 12rem;
    padding: 0 ${theme.spacing.m};
    &::placeholder {
      color: ${theme.palette.charcoal.solid[400]};
    }
  `,
  width &&
    css`
      width: ${width}px;
    `,
]);

const Border = styled.div(
  ({ theme }) => css`
    background-color: ${theme.palette.charcoal.solid[150]};
    bottom: 0;
    height: 0.0625rem;
    position: absolute;
    transition: background-color 0.3s;
    width: 100%;
  `,
);

const DropdownIcon = styled(Icon)<{ isOpen: boolean }>(({ theme, isOpen }) => [
  css`
    color: ${theme.palette.charcoal.solid[400]};
    position: absolute;
    right: ${theme.spacing.xs};
    bottom: ${theme.spacing.xs};
    transition: all 0.25s;
  `,
  isOpen &&
    css`
      color: ${theme.palette.charcoal.solid[600]};
      transform: rotate(180deg);
    `,
]);

const Option = styled.option<{ width?: number; type?: 'button' }>(({ theme, width, type }) => [
  css`
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 2.5rem;
    min-width: 13rem;
    padding: 0 ${theme.spacing.m};
    &:hover {
      background-color: ${theme.palette.charcoal.transparent[5]};
    }
  `,
  width &&
    css`
      width: calc(rem + ${width}px);
    `,
  type === 'button' &&
    css`
      background-color: ${theme.palette.charcoal.transparent[5]};
      border-bottom: 0.0625rem solid ${theme.palette.charcoal.solid[100]};
      color: ${theme.palette.charcoal.solid[700]};
      font-weight: 500;
      &:hover {
        background-color: ${theme.palette.charcoal.transparent[10]};
      }
    `,
]);

const ModalContent = styled.div(
  ({ theme }) => css`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    & > *:not(:first-child) {
      margin-top: ${theme.spacing.m};
    }
  `,
);

const Label = styled.label(({ theme, children }) => [
  css`
    font-size: ${theme.spacing.s};
  `,
  !children &&
    css`
      display: none;
    `,
]);

import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../auth';

export const GuardedRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const { token, userData } = useAuth();

  if (!token || !userData) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

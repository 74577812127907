import { IconButton } from '@@components';
import React from 'react';
import { useAuth } from 'src/auth';
import styled, { css } from 'styled-components';

export const TopBar: React.FC = () => {
  const { userData, logout } = useAuth();

  return (
    <Container>
      <UserWrapper>
        Witaj, <UserName>{userData?.name}</UserName>
      </UserWrapper>
      <IconButton icon="Logout" onClick={logout} size="m" iconSize="s" type="ghost" />
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    background-color: ${theme.palette.charcoal.solid[75]};
    border-bottom: 0.0625rem solid ${theme.palette.charcoal.solid[100]};
    height: 2.5rem;
    justify-content: space-between;
    padding: 0 1rem;
  `,
);

const UserWrapper = styled.div``;

const UserName = styled.span(
  ({ theme }) => css`
    color: ${theme.palette.trueBlue.solid[500]};
    font-weight: ${theme.typography.fontWeight.semiBold};
  `,
);

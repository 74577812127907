import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';

import { useClientActions, useClientState } from './Context';
import { AddClientModal } from './AddClientModal';
import { ToolBar, IndustryList } from './ToolBar';

import { DataTable, TableColumn } from '@@components';
import { GET_CLIENTS, GET_INDUSTRIES, DELETE_CLIENT } from './queries';
import { DeleteModal } from './DeleteModal';

import type { Client } from './types';
import { useAuth } from '../../../auth';

const columns: TableColumn<Client>[] = [
  {
    label: 'Imię',
    key: 'userName',
    icon: 'UserSearch',
    width: 180,
  },
  {
    label: 'Grupa Social Media',
    key: 'socialMediaGroup',
    icon: 'GlobalSearch',
    width: 450,
  },
  {
    label: 'Branża',
    key: 'industry',
    icon: 'Briefcase',
    width: 200,
  },
];

export const Content: React.FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [editableData, setEditableData] = useState<Client | null>(null);

  const { data } = useQuery<{ clients: Client[] }>(GET_CLIENTS);
  const industriesData = useQuery<{ industriesList: IndustryList[] }>(GET_INDUSTRIES);
  const [deleteClient] = useMutation<{ id: string }>(DELETE_CLIENT, { refetchQueries: [GET_CLIENTS] });

  const { setData, searchClient } = useClientActions();
  const { clients, searchedClients } = useClientState();
  const { userData } = useAuth();

  const industriesOptions = useMemo(
    () => industriesData.data?.industriesList.map(({ guid, name }) => ({ label: name, value: guid })) ?? [],
    [industriesData?.data],
  );

  const tableData: Client[] = useMemo(
    () => (searchedClients ?? clients).map((c) => ({ ...c, enabledActions: userData?.role === 'admin' || userData?.id === c.userId })),
    [searchedClients, clients, userData],
  );

  const handleSuccessAddClient = () => {
    searchClient('');
    setOpenModal(false);
  };

  const handleDelete = (id: string) => {
    setDeleteId(id);
  };

  const handleConfirmDelete = async () => {
    const result = await deleteClient({ variables: { id: deleteId } });

    result && setDeleteId(null);
  };

  const handleEdit = async (id: string) => {
    const dataToEdit = tableData.find(({ guid }) => guid === id);
    setEditableData(dataToEdit ?? null);
    setOpenModal(true);
  };

  useEffect(() => {
    if (data) {
      setData(data.clients);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Container>
      <DataWrapper>
        <ToolBar onAddClient={() => setOpenModal(true)} industriesList={industriesData.data?.industriesList} />
        <DataTable<Client> columns={columns} data={tableData} width={1000} allowActions onDelete={handleDelete} onEdit={handleEdit} />
      </DataWrapper>
      <AddClientModal
        isVisible={openModal}
        onClose={() => setOpenModal(false)}
        industriesOptions={industriesOptions}
        onSuccess={handleSuccessAddClient}
        editableData={editableData}
      />
      <DeleteModal id={deleteId} onClose={() => setDeleteId(null)} onConfirm={handleConfirmDelete} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
